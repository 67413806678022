/* eslint-disable react/jsx-no-bind */
import React from 'react'
import { Dropdown } from 'react-bootstrap'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu'
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle'
import { useDispatch, useSelector } from 'react-redux'
import { FILES_DELETE } from '../../../constant/apiurl'
import { postRequestcall } from '../../../api/postRequest'
import { setCallLogout, ShowModalConfirm, ShowToast } from '../../../redux/actions'
import Icon from '../../common/Icon'
import { getExtension } from './ReactMediaLibraryWrapper'

export default function FileMenu(props) {
    const access_token =  useSelector((state)=>state?.allReducers?.access_token);
    const MediaFile =  useSelector((state)=>state?.allReducers?.MediaFile);
    const dispatch = useDispatch();
    const callDelete = () =>{
        dispatch(ShowModalConfirm({
            Title:"Are you sure?",
            show:true,
            Description:" Are you really want to delete this file?",
            id:"",
            callBackModal:()=>callDeleteItemData(props.id),
            ButtonSuccess:"Delete File"
        }))
    }
    const callDeleteItemData =async (id)=>{
        const payload = {
            file_id:id
        }
        const response =await postRequestcall(FILES_DELETE, payload, access_token);
        console.log("res",response)
        if(response?.status === 403){
            dispatch(setCallLogout())
        }else if(response?.status === 400) {
            dispatch(ShowToast({
                description:response?.data?.error,
                show:true,
                type:"error"
            }))
        }
        else if(response?.status === 200) {
            dispatch(ShowToast({
                description:"File deleted successfully",
                show:true,
                type:"success"
            }))
        }
        props?.callMediaFiles(MediaFile?.pagination?.current_page);
        if(MediaFile?.data?.length === 0){
            if(MediaFile?.pagination?.current_page >1){
                props?.callMediaFiles(MediaFile?.pagination?.current_page-1);
            }else{
                props?.callMediaFiles(1);
            }
        }else{
            props?.callMediaFiles(MediaFile?.pagination?.current_page);
        }

        if(props?.selectedItem?.id === props?.id){
            props?.setSelectedItem((item)=>item?.filter((item)=>item.id !== props.id));
        }
    }
    const callShow = () =>{
        props?.SetModalPreview({
            show:true,
            file_path:props?.file_path
        })
    }
  return (
      <Dropdown className='user-menu file-menu' onClick={(e)=>e.stopPropagation()}>
          <DropdownToggle>
              <Icon className={"ellipsis-vertical"} />
          </DropdownToggle>
          <DropdownMenu >
            {(getExtension(props?.file_name) === "png" || getExtension(props?.file_name) === "svg") && 
            <DropdownItem onClick={callShow}>Open File</DropdownItem>}
            {
                getExtension(props?.file_name) !== "svg" && (
                      <DropdownItem onClick={callDelete} >Delete</DropdownItem>
                )
            }
          </DropdownMenu>
      </Dropdown>
  )
}

/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import minus_icon from "../../../assets/img/minus_icon.svg";
import plus_icon from "../../../assets/img/plus_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { ShowModalConfirm, setAddPoint, setCallLogout, setDeletePoint, setUpdatePoints } from "../../../redux/actions";
import EquipmentlinkPage from "./EquipmentlinkPage";
import Button from "../../common/Button";
import { useNavigate } from "react-router";
import InputGroup from "../../common/InputGroup";
import { postRequestcall } from "../../../api/postRequest";
import { DESIGN_UPDATE } from "../../../constant/apiurl";
import $ from 'jquery';
import EditTitlemodal from "../../common/EditTitlemodal";

export function PointervalueChange(props) {
  let scale = 1;
  if (props?.scale.toFixed(1) >= 1.2 && props?.scale?.toFixed(1) <= 1.4) {
    scale = 0.8;
  } else if (props?.scale.toFixed(1) >= 1.5 && props?.scale?.toFixed(1) <= 1.7) {
    scale = 0.7;
  }
  else if (props?.scale.toFixed(1) >= 1.8 && props?.scale?.toFixed(1) <= 2.5) {
    scale = 0.6;
  }
  return (
    <span onClick={props?.openEditForm}  onMouseDown={props?.editPoint} style={{ left: props?.item?.left, top: props?.item?.top, visibility: "visible", scale: scale.toString() }} id={"pointer"}>{props?.item?.sr_no}</span>
  )
}

export default function AddsvgPage() {

  const { allpoints, access_token, selectDesign } = useSelector((state) => state?.allReducers);
  let getId = window.location.pathname.split("/").pop();

  var allPolygon = $("svg > polygon");
  var allOther = $("svg");
  let panning = false;
  let pointX = 0;
  let pointY = 0;
  let start = { x: 0, y: 0 };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [showTitle,setShowtitle] = useState(false)
  const [contentdata, setContnentdata] = useState({ title: "", description: "", color: "" });
  const [position, setPosition] = useState(0);
  const [randomId, setRandomId] = useState("");
  const [show, setShow] = useState(false);
  const [pointer, setPointer] = useState();
  const [scale, setScale] = useState(1);
  const [designInfo, setDesignInfo] = useState("")
  const [showeditOption, setShoweditoption] = useState(false);
  const [contextmenu, setContextmenu] = useState(false);
  const [tagName, setTagName] = useState("");
  const [scalecontex, setScalecontex] = useState("1");

  useEffect(() => {
    if (getId !== "add") {
      plotPointeratEdit();
    } else {
      setIdtosvgChild();
    }
  }, []);

  const getNodelist = () => {
    let nodeList = $("svg").children();
    return nodeList;
  }

  const setIdtosvgChild = () => {
    let getNodes = getNodelist();
    for (var i = 0; i < getNodes.length; i++) {
      let randomId = Math.random().toString(16).slice(2);
      getNodes[i].setAttribute("id", randomId);
    }
  }

  allOther.off("contextmenu").on("contextmenu", function (e) {
    e.preventDefault();
    e.stopPropagation();
    let randomId = Math.random().toString(16).slice(2);
    setTagName(e.currentTarget.tagName);
    var pos_x = e.offsetX ? (e.offsetX) : e.pageX - document.getElementById("add-image").offsetLeft;
    var pos_y = e.offsetY ? (e.offsetY) : e.pageY - document.getElementById("add-image").offsetTop;
    var clientWidth = document.getElementById('add-image').clientWidth;
    var clientHeight = document.getElementById('add-image').clientHeight;
    pos_x = pos_x / clientWidth * 100;
    pos_y = pos_y / clientHeight * 100;
    const contextMenu = document.getElementById('contextMenu');
    contextMenu.style.left = pos_x + "%";
    contextMenu.style.top = pos_y + "%";
    let getValue = {
      id: randomId,
      sr_no: position + 1,
      title: "",
      description: "",
      color: "",
      top: pos_y + "%",
      left: pos_x + "%",
      selected_equipment: "",
      selected_media: ""
    };
    setRandomId(randomId);
    setPointer(getValue);
    setContextmenu(true);
  });

  allPolygon.off("contextmenu").on("contextmenu", function (e) {
    e.preventDefault();
    e.stopPropagation();
    var pos_x = e.offsetX ? (e.offsetX) : e.pageX - document.getElementById("add-image").offsetLeft;
    var pos_y = e.offsetY ? (e.offsetY) : e.pageY - document.getElementById("add-image").offsetTop;
    var clientWidth = document.getElementById('add-image').clientWidth;
    var clientHeight = document.getElementById('add-image').clientHeight;
    pos_x = pos_x / clientWidth * 100;
    pos_y = pos_y / clientHeight * 100;
    const contextMenu = document.getElementById('contextMenu');
    contextMenu.style.left = pos_x + "%";
    contextMenu.style.top = pos_y + "%";
    setTagName(e.currentTarget.tagName);
    setRandomId(e?.currentTarget?.id);
    console.log("hello",e?.currentTarget?.tagName);
    if (e?.currentTarget?.tagName === "polygon") {
      // If id not present in list then add other wise edit 
      let getColorpoint = allpoints?.filter((item) => item?.id === e?.currentTarget?.id.toString());
      console.log("getColorpoint",getColorpoint);
      if (getColorpoint?.length === 0) {
        let getValue = {
          id: e?.currentTarget?.id,
          sr_no: "",
          title: "",
          description: "",
          color: "#002868",
          top: pos_y + "%",
          left: pos_x + "%",
          selected_equipment: "",
          selected_media: ""
        }
        setPointer(getValue);
        setShoweditoption(false);
      } else {
        setContnentdata({
          title: getColorpoint[0]?.title,
          description: getColorpoint[0]?.description,
          color: getColorpoint[0]?.color
        })
        setShoweditoption(true); // For edit and remove
      }
    }
    setContextmenu(true);
  });


  const plotPointeratEdit = async () => {
    setDesignInfo(selectDesign?.design_title);
    if(selectDesign?.design_description?.allpoints?.length > 0) {
      let getSerialpoints = selectDesign?.design_description?.allpoints?.map((item) => {
        if (item?.color !== "")
          $('#' + item?.id).css('fill', item?.color);
        if (item.sr_no !== "")
          return item
        return []
      })
      if (getSerialpoints.length > 0) {
        let getLastindex = getSerialpoints?.length - 1; // get last index of points
        let getlastposition = getSerialpoints[getLastindex]?.sr_no // get last position of points
        setPosition(getlastposition) // For next position set the prev position
      }
      dispatch(setUpdatePoints(selectDesign?.design_description?.allpoints)); // all points update to redux
    }
  }

  function setTransform(pointX, pointY, type, scale) {
    document.getElementById("svg-editor").style.transform = `translate(${pointX}${type} , ${pointY}${type}) scale(${scale})`;
  }

  const zoomOut = (e) => {
    e.preventDefault();
    if (scale >= 0.6) {
      let getScale = scale - 0.1
      setScale(getScale);
      setTransform(pointX, pointY, "px", getScale);
    }
  }

  const zoomIn = (e) => {
    e.preventDefault();
    if (scale <= 2.5) {
      let getScale = scale + 0.1
      setScale(getScale);
      setTransform(pointX, pointY, "px", getScale);
      let updatedscale;
      if (getScale?.toFixed(1) >= 1.2 && getScale?.toFixed(1) <= 1.4) {
        updatedscale = 0.8;
      } else if (getScale.toFixed(1) >= 1.5 && getScale?.toFixed(1) <= 1.7) {
        updatedscale = 0.7;
      }
      else if (getScale?.toFixed(1) >= 1.8 && getScale?.toFixed(1) <= 2.5) {
        updatedscale = 0.6;
      }
      setScalecontex(updatedscale.toString())
    }
  }


  // Edit work on right click
  const editPoint = (e, id) => {
    var rightclick;
    if (e.which) rightclick = (e.which === 3);
    else if (e.button) rightclick = (e.button === 2);
    if (rightclick === true) {
      e.preventDefault();
      e.stopPropagation();
      setShoweditoption(true);
      setRandomId(id);
      setTagName("svg");
      let getPointer = allpoints.filter((item) => item.id === id);
      setContnentdata({
        title: getPointer[0]?.title,
        description: getPointer[0]?.description
      })
      const contextMenu = document.getElementById('contextMenu');
      contextMenu.addEventListener('contextmenu', (ev) => {
        ev.preventDefault(); // this will prevent browser default behavior 
      });
      contextMenu.style.left = getPointer[0].left;
      contextMenu.style.top = getPointer[0].top;
    }
    setContextmenu(true);
  }

  const resetSvg = (e) => {
    e.preventDefault();
    setScale(1);
    pointX = 0
    pointY = 0
    setTransform(pointX, pointY, "px", 1);
    setScalecontex("1")
  }

  const onMouseDownchange = (e) => {
    var rightclick;
    if (e.which) rightclick = (e.which === 3);
    else if (e.button) rightclick = (e.button === 2);
    if (typeof rightclick === "undefined") {
      if (e.cancelable) e.preventDefault();
      start = { x: e.clientX - pointX, y: e.clientY - pointY };
      panning = true;
    }else{
      setShoweditoption(false);
      setContextmenu(false);
    }
  }

  const onWheelchange = (e) => {
    var rightclick;
    if (e.which) rightclick = (e.which === 3);
    else if (e.button) rightclick = (e.button === 2);
    if (typeof rightclick === "undefined") {
      if (e.cancelable) e.preventDefault();
      let getScale = scale;
      let xs = (e.clientX - pointX) / getScale;
      let ys = (e.clientY - pointY) / getScale;
      let delta = (e.wheelDelta ? e.wheelDelta : -e.deltaY);
      (delta > 0) ? (getScale *= 1.1) : (getScale /= 1.1);
      pointX = e.clientX - xs * getScale;
      pointY = e.clientY - ys * getScale;
      setTransform(pointX, pointY, "px", getScale);
    }
  }

  const onMouseUpchange = (e) => {
    if (e.cancelable) e.preventDefault();
    panning = false;
  }

  const onMouseMovechange = (e) => {
    if (e.cancelable) e.preventDefault();
    if (!panning) {
      return;
    }
    pointX = (e.clientX - start.x);
    pointY = (e.clientY - start.y);
    setTransform(pointX, pointY, "px", scale);
  }

  const addPointer = (e) => {
    e.preventDefault();
    setContextmenu(false); // context menu hide
    console.log("tag name",tagName,pointer)
    if (tagName === "polygon") {
      $("#" + pointer?.id).css("fill", "#002868");
    } else {
      setRandomId(pointer?.id);
      setPosition(pointer?.sr_no);
    }
    dispatch(setAddPoint(pointer))
    setContnentdata({
      title: "",
      description: "",
      color: ""
    })
    setShow(true);
  }

  const editPointer = (e) => {
    e.preventDefault();
    setShow(true);
    setContextmenu(false);
  }

  const removePointer = (e) => {
    e.preventDefault();
    dispatch(ShowModalConfirm({
      Title:"Are you sure?",
      show:true,
      Description:"Do you really want to remove the current point the changes will be autosaved ?",
      id:"",
      callBackModal:()=>removedesignPointer(),
      ButtonSuccess:"Remove"
  }));
  }

  const removedesignPointer = () => {
    if (allpoints?.length > 0) {
      if (tagName === "polygon")
       $("#" + randomId).removeAttr("style");
      dispatch(setDeletePoint(randomId));
      let getAllpoints = allpoints?.filter((item) => item?.id !== randomId);
      updateDesignfile(getAllpoints,designInfo)
     if (allpoints?.length === 1) {
       setPosition(0)
     } else {
       setPosition(position - 1)
     }
   }
   setShoweditoption(false);
   setContextmenu(false);
  }

  const onHidemenu = (e) => {
    e.preventDefault();
  }

  const getUpdatedBase64design = async () => {
    // If at the time of update remove that polygon_div class and style because polygon_div not to pass we used in css
    allPolygon.removeClass("polygon_div");
    var svg = document.getElementsByTagName('svg')[0];
    // Convert the SVG node to HTML 
    var div = document.createElement('div');
    div.appendChild(svg.cloneNode(true));
    let value = window.btoa(div.innerHTML);
    console.log("base 64",value.toString());
    var b64 = "data:image/svg+xml;base64," + value.toString();
    return b64;
  }

  // Update the design file 
  const updateDesignfile = async (allPoints,title) => {
      let pointer;
      //$("#" + item?.id).removeAttr("style");
      let getAllpoints = allPoints?.map((item) => {
        return pointer = {
          id: item?.id,
          sr_no: item?.sr_no,
          title: item?.title,
          color: item?.color,
          description: item?.description,
          left: item?.left,
          top: item?.top,
          selected_equipment: item?.selected_equipment,
          selected_media: item?.selected_media,
        }
      });
      let payload = {
        design_title: title,
        design_description: JSON.stringify({ allpoints: getAllpoints }),
        design_id: selectDesign?.id,
      }
      let getBase64 = await getUpdatedBase64design();
      payload.design_svg = getBase64.toString();
      let getResponse = postRequestcall(DESIGN_UPDATE, payload, access_token);
      if (getResponse.status === 403) {
        navigate("/");
        dispatch(setCallLogout());
      }
  }

  const showPopupforTitle = (e) => {
    e.preventDefault();
    setShowtitle(true)
  }

  const openEditForm = (e,id) => {
    e.preventDefault();
    setRandomId(id);
    setTagName("svg");
    let getPointer = allpoints.filter((item) => item.id === id);
    setContnentdata({
      title: getPointer[0]?.title,
      description: getPointer[0]?.description
    });
    setShow(true);
  }

  return (
    <>
      <aside>
        <Row>
          <Col className='mt-3'>
            <Card className="add-svg" onContextMenu={(e) => onHidemenu(e)}>
              <Card.Header className=''>
                <div className="d-flex align-items-center">
                  {/* <h5 onClick={(e)=>updateDesignfile(e)}>Update file</h5> */}
                  <div onClick={(e) => zoomIn(e)} className="d-flex align-items-center zoom">
                    <i className="bi bi-zoom-in icon" title="Zoom In"></i>
                  </div>
                  <div onClick={(e) => zoomOut(e)} className="d-flex align-items-center zoom mx-2">
                    <i className="bi bi-zoom-out icon" title="Zoom Out"></i>
                  </div>
                  <Button type={"submit"} className={"reset-btn"} children={"Reset"} onClick={(e) => resetSvg(e)}><i className="bi bi-arrows-fullscreen" title="Resize"></i></Button>
                </div>
                <div className="col-4">
                  <InputGroup
                    placeholder='Enter design title'
                    name={"title"}
                    formClass="mb-0"
                    value={designInfo}
                    require
                    errors={""}
                    rightIcon={"edit"}
                    rightClick={(e) => showPopupforTitle(e)}
                    readOnly={true}
                  />
                </div>
              </Card.Header>
              <Card.Body className='pt-0 pb-0'>
                <div id="add-image">
                  <div id="svg-editor"
                    onMouseDown={(e) => onMouseDownchange(e)}
                    onWheel={(e) => onWheelchange(e)}
                    onMouseUp={(e) => onMouseUpchange(e)}
                    onMouseMove={(e) => onMouseMovechange(e)}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: selectDesign?.svg_file }}
                      id="pointer_div"
                    />
                    {allpoints?.map((item, index) => (
                      item?.sr_no !== "" && (
                        <PointervalueChange openEditForm={(e)=>openEditForm(e,item?.id)} editPoint={(e) => editPoint(e, item?.id)} item={item} scale={scale} />
                      )
                    ))}
                    <div id="contextMenu" className="context-menu" style={{ display: !contextmenu ? "none" : "block", scale: scalecontex }}>
                      <ul className="menu">
                        {!showeditOption && (<li onClick={(e) => addPointer(e)}>Add</li>)}
                        {showeditOption && (
                           <>
                             <li onClick={(e) => editPointer(e)}>Edit</li>
                             <li onClick={(e) => removePointer(e)}>Remove</li>
                           </>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <EquipmentlinkPage designInfo={designInfo} updateDesignfile={updateDesignfile} tagName={tagName} setShow={setShow} contentdata={contentdata} setContnentdata={setContnentdata} show={show} randomId={randomId} position={position} setPosition={setPosition} />
        <EditTitlemodal updateDesignfile={updateDesignfile} setDesignInfo={setDesignInfo} showTitle={showTitle} setShowtitle={setShowtitle} designInfo={designInfo} /> 
      </aside>
    </>
  )

}

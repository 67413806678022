/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-bind */
import React, { useState } from "react";
import { Card, ListGroup, ListGroupItem } from "react-bootstrap";
import { useSelector } from "react-redux";
import Icon from "../common/Icon";
import $ from "jquery";
import chevron_down from "../../assets/img/chevron_down.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "../common/Image";
import { getExtension } from "../dashboardpanel/media/ReactMediaLibraryWrapper";
import formatBytes from "../../utils/formatBytes";
import formatDate from "../../utils/formatDate";
import close_icon from "../../assets/img/close-btn.svg";


export default function ViewerSideBar(props) {
  
  const selectDesign = useSelector((state) => state?.allReducers?.selectDesign);
  const selectPointer = useSelector(
    (state) => state?.allReducers?.selectPointer
  );
  console.log("selectPointer",selectPointer?.id)
  const [previd, setPrevid] = useState();
  const {
    ShowEquipment,
    setShowEquipment,
    tabState,
    setTabState,
    equipmentMeta,
    setEquipmentmeta,
  } = props;
  const [displayDocument, setDisplaydocument] = useState(false);

 const paths = document.querySelectorAll("path, ellipse, rect");
 paths.forEach((path) => path.classList.remove("onPathClick"));

  const element = document.getElementById(selectPointer?.id);
  element?.classList.add("onPathClick")
  const pointerMousehover = (e, item) => {
    if (props?.hover !== item) {
      props?.setHover(item?.id);
      setPrevid(item?.id);
      $("#" + previd).removeAttr("style");
      $("#" + item?.id).css("fill", item?.color);
    }
  };

  const pointerMouseleave = (e, item) => {
    if (props?.hover !== null) {
      props?.setHover(null);
      $("#" + item?.id).removeAttr("style");
    }
  };

  const showEquipmenttable = () => {
    if (document.getElementById("equ-show").className.includes("active")) {
      document.getElementById("equ-show").classList.remove("active");
      props?.setDisplayequipment(true);
    } else {
      document.getElementById("equ-show").classList.add("active");
      props?.setDisplayequipment(false);
    }
  };

  const showDocumenttable = () => {
    if (!displayDocument) {
      document.getElementById("document-arrow").classList.add("transition");
      setDisplaydocument(true);
    } else {
      document.getElementById("document-arrow").classList.remove("transition");
      document
        .getElementById("document-arrow")
        .classList.add("transition-document");
      setDisplaydocument(false);
    }
  };

  const backTolist = () => {
    setShowEquipment(false);
  };

  const onSelectequipment = (e, item) => {
    e.preventDefault();
    setEquipmentmeta(item?.equipment_meta);
    setTabState(item?.id);
  };

  const closeSearchresult = (e) => {
    e.preventDefault();
    document.getElementById("design-viewer").style.removeProperty("width");
    document.getElementById("leftarrow").style.display = "block";
  };
  const inActiveEquipmentMetaArray = [];
  return (
    <div className={"floor-sidebar"} id={"floor-sidebar"}>
      <div className="floor-sidebar-wrapper">
        {ShowEquipment ? (
          <div className="sidebar-list-wrapper">
            <div className="sidebar-header svg-result justify-content-between">
              <div
                className="d-flex align-items-center"
                onClick={() => backTolist()}
              >
                <Icon className="arrowleft primary" />
              </div>
              <i
                className="bi bi-x close-icon"
                onClick={(e) => closeSearchresult(e)}
              ></i>
            </div>
            <div className="equipment-data" id="equ-show">
              <section className="header">
                <h5 className="equip-header mb-0">Equipments</h5>
                <span
                  className="arrow transition"
                  id={"arrow-id"}
                  onClick={() => showEquipmenttable()}
                >
                  <img alt="" src={chevron_down} />
                </span>
              </section>
              {props?.displayEquipment && (
                <section className="content">
                  <Slider
                    arrows={true}
                    speed={500}
                    slidesToShow={3}
                    slidesToScroll={3}
                    infinite={false}
                  >
                   {/* {console.log(
                      "selectPointer?.selected_equipment",
                      selectPointer?.selected_equipment
                    )}  */}
                    {selectPointer?.selected_equipment &&
                      selectPointer?.selected_equipment.map((item, index) => {
                        // console.log("index:", index, "item:", item); // Check if this works

                        // Check if item is undefined, and skip rendering if true
                        if (!item) {
                          return null;
                        }

                        if (item?.is_active) {
                          return (
                            <button
                              type="button"
                              className={`equipment-btn ${
                                tabState === item?.id ? "active" : ""
                              }`}
                              id={"slide-item" + item?.id}
                              onClick={(e) => onSelectequipment(e, item)}
                              key={item?.id} // Use a unique key for each item, item.id seems to be a suitable option
                            >
                              {item?.equipment_title}
                            </button>
                          );
                        } else {
                          for (const element of item?.equipment_meta) {
                            inActiveEquipmentMetaArray.push(element);
                          }
                          console.log(
                            "inActiveEquipmentMetaArray",
                            inActiveEquipmentMetaArray
                          );
                        }

                        // Return null for inactive items
                        return null;
                      })}
                  </Slider>
                  <div className="table-responsive equ-table">
                    <table className="table table-bordered table-hover equipment-table mb-0">
                      <tbody>
                        {/* If data is not present then show no record found */}
                        {/* {console.log("equipmentMeta:", equipmentMeta)} */}
                        {equipmentMeta === undefined ||
                        equipmentMeta.length === 0 ? (
                          <tr>
                            <td className="text-center" colSpan={2}>
                              No Records Found
                            </td>
                          </tr>
                        ) : (
                          equipmentMeta.map((item, index) => {
                            // Check if there is a corresponding item with the same key and value in inActiveEquipmentMetaArray
                            const foundInInactiveArray =
                              inActiveEquipmentMetaArray.some(
                                (inactiveItem) =>
                                  inactiveItem.key === item?.key &&
                                  inactiveItem.value === item?.value
                              );

                            // If a match is found, skip rendering this item
                            if (foundInInactiveArray) {
                              return null;
                            }

                            // console.log("item loop", item); // Move console.log here
                            return (
                              <tr key={index}>
                                <th>{item?.key}</th>
                                <td>{item?.value}</td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </section>
              )}
            </div>
            <div className="equipment-data">
              <section className="header">
                <h5 className="equip-header mb-0 text-primary">Documents</h5>
                <span
                  className="arrow"
                  id={"document-arrow"}
                  onClick={() => showDocumenttable()}
                >
                  <img alt="" src={chevron_down}></img>
                </span>
              </section>
              {displayDocument && (
                <section className="content">
                  <div className="attach-media">
                    {selectPointer?.selected_media?.map((item, index) => (
                      <Card
                        className="p-0 mb-2"
                        key={index?.toString()}
                        onClick={() => props?.callShowPreview(item)}
                      >
                        <Card.Body>
                          <Image className={getExtension(item?.file_name)} />
                          <div className="file-details">
                            <Card.Title className="mb-0">
                              {item?.file_real_name}
                            </Card.Title>
                            <span className="file-info">
                              <label>{formatBytes(item?.file_size)}</label>
                              <label>
                                {formatDate(new Date(item?.created_at))}
                              </label>
                            </span>
                          </div>
                        </Card.Body>
                      </Card>
                    ))}
                  </div>
                </section>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className="relative svg-poiner-list pointer-list position-relative">
              <div className="d-flex gap-2 align-items-center svg-result justify-content-end">
                {" "}
                {/*  justify-content-between */}
                {/* <h6>
                  Search Result (
                  {selectDesign?.design_description?.allPoints?.length})
                </h6> */}
                <i
                  className="bi bi-x close-icon"
                  onClick={(e) => closeSearchresult(e)}
                ></i>
                {/* <img alt="" style={{height:"25px",width:"25px",cursor:"pointer"}} src={close_icon} className="close-icon" onClick={(e)=>closeSearchresult(e)} /> */}
              </div>
              <div className="px-2 text-sm title-text">
                <Card className="p-0 bg-ligh-grey border-radius-1">
                  <Card.Body className="p-2">
                    {selectDesign?.design_title}
                  </Card.Body>
                </Card>
              </div>

              <div className="sidebar-list-wrapper mt-4">
                <ListGroup variant="flush">
                  {selectDesign?.design_description?.allPoints?.map(
                    (item, index) => {
                      // console.log("Current item:", item);
                      // console.log(
                      //   "from viewsidebar",
                      //   selectDesign?.design_description?.allPoints
                      // );

                      return (
                        <ListGroupItem
                          className={index % 2 === 0 ? "even" : "odd"}
                          onMouseOver={(e) => pointerMousehover(e, item)}
                          onClick={() => props?.CallSelectPointer(item)}
                          onMouseLeave={(e) => pointerMouseleave(e, item)}
                          action
                          key={index?.toString()}
                        >
                          <div className="me-auto">
                            <h6 className="fw-bold w-100">{item?.title}</h6>
                            <div
                              className="pointer-description"
                              dangerouslySetInnerHTML={{
                                __html: item?.description,
                              }}
                            />
                          </div>
                        </ListGroupItem>
                      );
                    }
                  )}
                </ListGroup>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

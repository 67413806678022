/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { setCallBackFileSelect, setShowEquipmentmodal } from "../../../redux/actions";
import Button from "../../common/Button";
import Selectlist from "../../common/Selectlist";
import Texteditor from "../../common/TextEditor";
import { CallGetEquipmentList } from "../../../redux/actions/utils";
import { getRequestcall } from "../../../api/getRequest";
import { EQUIPMENT_DETAIL, FILE_DETAIL } from "../../../constant/apiurl";
import InputGroup from "../../common/InputGroup";
import $ from 'jquery';
import ModalEquipmentPage from "../equipment/ModalEquipmentPage";

export default function EquipmentlinkPage(props) {

  const dispatch = useDispatch();
  const checkUrl = window.location.pathname.split("/");
  const { allpoints, callBackMediaFile, access_token } = useSelector((state) => state?.allReducers)
  const equipmentList = useSelector((state) => state?.allReducers?.equipment);
  const [selectedPoint, setSelectedPoint] = useState([]);
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    selected_equipment: "",
  });
  const [showlist, setShowlist] = useState(false);
  const [equiplist, setEquiplist] = useState([]);
  const [selectedEquipment, setSelectedequipment] = useState([])
  const [position, setPosition] = useState();
  
  useEffect(() => {
    getEquipmentlist();
  }, [])

  const getEquipmentlist = async () => {
      dispatch(await CallGetEquipmentList("all", 1));
  }

  const valueOnchange = (e) => { // added if condition here for first letter of title should be capital
    e.preventDefault();
  
    if (e.target.name === "title") {
      const capitalizedFirstLetter = e.target.value.charAt(0).toUpperCase();
      const restOfString = e.target.value.slice(1);
      props?.setContnentdata({
        ...props?.contentdata,
        [e.target.name]: capitalizedFirstLetter + restOfString,
      });
    } else {
      props?.setContnentdata({
        ...props?.contentdata,
        [e.target.name]: e.target.value,
      });
    }
  
    setErrors({
      ...errors,
      title: "",
    });
  };
  

  const descriptionOnchange = (e) => {
    props?.setContnentdata({
      ...props?.contentdata,
      description: e,
    })
    setErrors({
      ...errors,
      description: ""
    })
  }

  const validation = () => {
    let value = true;
    if (props?.contentdata?.title === "") {
      errors.title = "Enter title";
      value = false;
    }
    if (props?.contentdata?.description === "") {
      errors.description = "Enter description";
      value = false;
    }
    if (selectedEquipment?.length === 0) {
      errors.selected_equipment = "Select equipment";
      value = false;
    }
    setErrors({
      ...errors,
      title: errors?.title,
      description: errors?.description,
      selected_equipment: errors?.selected_equipment,
    })
    return value;
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (validation()) {
      if (selectedPoint?.length > 0) {
        let selectedfile = "";
        let selectedEqu = "";
        if (callBackMediaFile?.data?.length > 0) {
          selectedfile = callBackMediaFile?.data.map((item) => { return item?.id; }).join(",");
        }
        if (selectedEquipment.length > 0) {
          selectedEqu = selectedEquipment.map((item) => { return item?.key; }).join(",");
        }
        selectedPoint[0].title = props?.contentdata?.title;
        selectedPoint[0].description = props?.contentdata?.description;
        selectedPoint[0].selected_equipment = selectedEqu;
        selectedPoint[0].selected_media = selectedfile;
        if (props?.tagName === "polygon") {
          selectedPoint[0].color = props?.contentdata?.color;
          $("#" + selectedPoint[0].id).css("fill", selectedPoint[0].color);
        }
        // Update editable the points 
        let getUpdatedpoint = allpoints?.map((item) => {
          if (item.id === selectedPoint[0].id) {
            return selectedPoint[0];
          } else {
            return item;
          }
        });
        props?.updateDesignfile(getUpdatedpoint, props?.designInfo);
        props?.setShow(false);
        props?.setContnentdata({
          title: "",
          description: ""
        });
      }
    }
  }

  const returnSelectedPoint = () => {
    return allpoints?.filter((item) => (item.id === props?.randomId));;
  }

  const openEquipmentform = () => {
    let getvalue = returnSelectedPoint();
    if (getvalue?.length > 0) {
      setSelectedPoint(getvalue);
      setSelectedequipment([]); // clear prev selected equipment
      dispatch(setCallBackFileSelect({ data: [] })) // clear prev selected media
      showSelectedequiandMedia(getvalue);  // Show selected media and equipment 
    }
  }

  const showSelectedequiandMedia = async (getvalue) => {
    var getSelectedEquipment = getvalue[0]?.selected_equipment?.split(",");
    var getSelectedMedia = getvalue[0]?.selected_media?.split(",");
    let getlist = equipmentList?.data?.map((item) => ({ key: item?.id, value: item?.equipment_title }));  // Get equipment list 
    if (getvalue[0]?.selected_equipment !== "") {
      let updatedlist = getlist.filter(val => { return !getSelectedEquipment.find((val2) => { return val.key === val2 }) });
      setEquiplist(updatedlist) // selected equipment not show in equipment list
    } else {
      setEquiplist(getlist);
    }
    if (getvalue[0]?.selected_equipment !== "") {
      let getEquipment = await Promise.all(getSelectedEquipment?.map(async (item) => {
        let getResponse = await getRequestcall(EQUIPMENT_DETAIL + item, access_token)
        if (getResponse?.status === 200) {
          let getSelected = {};
          return getSelected = {
            key: getResponse?.data?.data?.id,
            value: getResponse?.data?.data?.equipment_title
          }
        } else if (getResponse?.status === 400) {
          return undefined;
        }
      }))
      getEquipment = getEquipment?.filter((item) => item !== undefined)
      if (getEquipment?.length > 0)
        setSelectedequipment(getEquipment);
    }
    if (getvalue[0]?.selected_media !== "") {
      let getMedia = await Promise.all(getSelectedMedia?.map(async (item) => {
        let getResponse = await getRequestcall(FILE_DETAIL + item, access_token)
        if (getResponse?.status === 200) {
          let getSelected = {};
          return getSelected = {
            id: getResponse?.data?.data?.id,
            file_real_name: getResponse?.data?.data?.file_real_name
          }
        } else if (getResponse?.status === 400) {
          return undefined;
        }
      }))
      getMedia = getMedia?.filter((item) => item !== undefined)
      if (getMedia?.length > 0)
        dispatch(setCallBackFileSelect({ data: getMedia }))
    }
  }

  const onSelectEquipment = (e, select, index) => {
    e.preventDefault();
    let getItem = [...selectedEquipment];
    setPosition(index);
    getItem.push(select);
    setSelectedequipment(getItem);
    let getList = equiplist?.filter((item, index) => item?.key !== select?.key)
    setEquiplist(getList);
    setShowlist(false);
    setErrors({
      ...errors,
      selected_equipment: ""
    })
  }

  const removeSelectedequipment = (e, item) => {
    e.stopPropagation();
    e.preventDefault();
    let updateSelected = selectedEquipment?.filter((select) => select?.key !== item?.key)
    setSelectedequipment(updateSelected);
    let updateEqui = [...equiplist]; // After remove add into the list to previous position
    updateEqui.splice(position, 0, item);
    setEquiplist(updateEqui);
  }

  const removeSelectedMedia = (e, item) => {
    e.stopPropagation();
    e.preventDefault();
    let updateSelected = callBackMediaFile?.data?.filter((select) => select?.id !== item?.key)
    dispatch(setCallBackFileSelect({ data: updateSelected }))
  }

  const renderSelectedmedia = (media) => {
    let getList = media?.data?.map((item) => {
      let media;
      return media = {
        key: item?.id,
        value: item?.file_real_name
      }
    })
    return getList;
  }

  const handleClose = () => {
    props?.setContnentdata({
      title: "",
      description: "",
      color: ""
    })
    props?.setShow(false);
  }


  const openEquipment = () => {
    dispatch(setShowEquipmentmodal(true)); 
  }

  return (
    <React.Fragment>
      <Offcanvas
        show={props?.show} placement={"end"} onHide={handleClose} onShow={() => openEquipmentform()} className="equipment-offcanvas">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Equipment</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0">
          <Form className="pointer-form">
            <Form.Group className="mb-2">
              <Form.Label className="mb-0">Select Equipment</Form.Label>
                <div className="w-100">
                  <Selectlist
                    placeholder={"Select equipment"}
                    type="equipment"
                    list={equiplist}
                    groupItemClick={onSelectEquipment}
                    showlist={showlist}
                    setShowlist={setShowlist}
                    selectedItem={selectedEquipment}
                    removeItem={removeSelectedequipment}
                  />
                </div>
                 <Button variant="success" className="add-new" onClick={() => openEquipment()}>
                   Add New 
                </Button>
              <div>
                <span className="text-danger">{errors?.selected_equipment}</span>
              </div>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="mb-0">Select Media (Optional)</Form.Label>
              <Selectlist
                placeholder={"Select media"}
                type="media"
                showlist={showlist}
                setShowlist={setShowlist}
                selectedItem={renderSelectedmedia(callBackMediaFile)}
                removeItem={removeSelectedMedia}
              />
            </Form.Group>
            {props?.tagName === "polygon" && (
              <Form.Group className="mb-2">
                <InputGroup
                  label="Color"
                  placeholder="Choose a color"
                  className={"color-picker"}
                  formClass="mb-2"
                  name={"color"}
                  onChange={valueOnchange}
                  value={props?.contentdata?.color}
                  type={"color"}
                />
              </Form.Group>
            )}
            <Form.Group className="mb-2">
              <InputGroup
                label='Title'
                placeholder='Enter title'
                formClass='mb-2'
                name={"title"}
                onChange={valueOnchange}
                value={props?.contentdata?.title}
                type={"text"}
              />
              <span className="text-danger">{errors?.title}</span>
            </Form.Group>
            <Form.Group className="mb-2">
              <Texteditor
                name="description"
                placeholder='Enter Description hear...'
                label='Description'
                value={props?.contentdata?.description}
                onChange={(e) => descriptionOnchange(e)}
                require
              />
              <span className="text-danger">{errors?.description}</span>
            </Form.Group>
            <Button variant="success" className="save-btn" onClick={(e) => submitForm(e)}>
              Save
            </Button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalEquipmentPage designHide={true} selectedEquipment={selectedEquipment} setSelectedequipment={setSelectedequipment}/>
    </React.Fragment>
  )
}

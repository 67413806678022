import { setStoreUserLists, setCallLogout, setStoreEquipmentLists, setStoreFileLists,setDesignlist, setClearviewerDesign } from "./index";
import { getRequestcall } from "../../api/getRequest";
import { USERS_LIST, EQUIPMENT_LIST, FILES_LIST,DESIGN_LIST, DESIGN_GET } from "../../constant/apiurl";

export const CallGetUsersList = async (type, current_page) =>
(async (dispatch, getState)=>{
    const { access_token, record_limit } = getState()?.allReducers;
    const url =  USERS_LIST +`/${type}/${record_limit|| 10}/${current_page}`
    const response = await getRequestcall(url, access_token);
    if(response?.status === 200){
        dispatch(setStoreUserLists(response?.data))
    }else{
        dispatch(setStoreUserLists({
            data:[],
            pagination:{
                current_page:1,
                record_limit:record_limit,
                total_records:0
            }
        }));
        if(response?.status === 403){
            dispatch(setCallLogout())
        }
    }
})

// It is used for design list 
export const CallGetDesignList = async (type, current_page) =>
(async (dispatch, getState) => {
    const { access_token, record_limit} = getState()?.allReducers;
    const url =  DESIGN_LIST +`/${type}/${record_limit || 10}/${current_page}` // It is used for design list
    const response = await getRequestcall(url, access_token);
    if(response?.status === 200) {
        if(response?.data?.data?.length === 0) {
            dispatch(setClearviewerDesign());
            dispatch(setDesignlist(response?.data));
        }else{
            dispatch(setDesignlist(response?.data));
        }
    }
    else{
        dispatch(setDesignlist({
            data:[],
            pagination:{
                current_page:1,
                record_limit:record_limit,
                total_records:0
            }
        }));
        if(response?.status === 403){
            dispatch(setCallLogout())
        }
    }
})

// Get Equipment List
export const CallGetEquipmentList = async (type, current_page) =>
(async (dispatch, getState) => {
    const { access_token, equipment, record_limit } = getState()?.allReducers;
    const checkUrl = window.location.pathname.split("/");
    let getUrl = "";
    if(checkUrl[2] === "edit") {
      // At the time of design edit pass design id and get equipment
      getUrl = EQUIPMENT_LIST +`/${checkUrl[3]}/5000/${current_page}`;
    } else {
        if(checkUrl[1]=== "equipment"){
            getUrl = EQUIPMENT_LIST +`/${type}/${record_limit || 10}/${current_page}`;
        }
    }
    const response = await getRequestcall(getUrl, access_token);
    if(response?.status === 200){
        dispatch(setStoreEquipmentLists(response?.data))
    }else{
        dispatch(setStoreEquipmentLists({
            data:[],
            pagination:{
                current_page:1,
                record_limit:record_limit,
                total_records:0
            }
        }));
        if(response?.status === 403){
            dispatch(setCallLogout())
        }
    }
})

// Get Equipment List
export const CallGetFilesList = async (type, current_page) =>
(async (dispatch, getState)=>{
    const { access_token, MediaFile, record_limit } = getState()?.allReducers;
    const url =  FILES_LIST +`/${type}/${record_limit|| 10}/${current_page}`;
    const response = await getRequestcall(url, access_token);
    if(response?.status === 200){
        dispatch(setStoreFileLists(response?.data))
    }else{
        dispatch(setStoreFileLists({
            data:[],
            pagination:{
                current_page:1,
                record_limit:record_limit,
                total_records:0
            }
        }));
        if(response?.status === 403){
            dispatch(setCallLogout())
        }
    }
    console.log("in utils CallGetFilesList")
})

// Get Design detail 
export const callDesigndetail = async (access_token,design_id) => {
    const response = await getRequestcall(`${DESIGN_GET}/${design_id}`, access_token);
    if (response?.status === 200) {
      return response?.data?.data;
    } 
}

 



import React from "react";
import { Modal } from "react-bootstrap";
import AddEquipmentPage from "./AddEquipmentPage";
import close_icon from "../../../assets/img/close-btn.svg"
import { setShowEquipmentmodal } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

function ModalEquipmentPage (props) {

 const { showEquipmentmodal } = useSelector((state) => state?.allReducers);
 const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(setShowEquipmentmodal(false)); 
  }
    
    return (
        <Modal size="lg" className="equipment-modal" centered show={typeof showEquipmentmodal === "undefined" ? false : showEquipmentmodal}>
         {/* <img alt="" src={close_icon} className="close-icon" /> */}
         <i className="bi bi-x close-icon" onClick={()=>closeModal()}></i>
        <Modal.Body>
          <AddEquipmentPage designHide={props?.designHide} title={"Add Equipments"} selectedEquipment={props?.selectedEquipment} setSelectedequipment={props?.setSelectedequipment} breadcrumb={"add"}/>
        </Modal.Body>
      </Modal>
    )

}
export default ModalEquipmentPage;
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from "react";
import { Col, Form, FormControl, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ADD_USER, DESIGN_LIST, UPDATE_USER } from "../../../constant/apiurl";
import { getRequestcall } from "../../../api/getRequest";
import { postRequestcall } from "../../../api/postRequest";
import { setCallLogout, ShowToast } from "../../../redux/actions";
import Button from "../../common/Button";
import Icon from "../../common/Icon";
import InputGroup from "../../common/InputGroup";
import ReactSelect from "../../common/ReactSelect";
import {
  checkPasswordValidity,
  validateEmail,
} from "../../loginscreen/LoginPage";

export default function AddUsers(props) {
  // Get the current URL
  const currentURL = window.location.href;
  const urlSegments = currentURL.split("/");
  const id = urlSegments[urlSegments.length - 1];

  const { access_token } = useSelector((state) => state?.allReducers);
  const [PassType, setPassType] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const location = useLocation();
  const [options, setOptions] = useState([]);

  const [type, setType] = useState(state?.title);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
    designs: "",
    is_admin: 0,
  });
  const [designs, setDesigns] = useState([]);
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    name: "",
    designs: "",
    is_admin: "",
  });
  const [showDesign, setShowdesign] = useState(true);

  useEffect(() => {
    if (state?.title === "edit") {
      CallSetStatusCall();
    }
    if (state?.title === "add" || props?.type === "add") {
      setType("add");
    }
    if (!state?.title && props?.type !== "add") {
      navigate("/Users");
    }
  }, [state?.title]);

  useEffect(() => {
    callGetDesignList();
  }, []);

  const callGetDesignList = async () => {
    const URL = DESIGN_LIST + `/all/5000/1`;
    const response = await getRequestcall(URL, access_token);
    if (response?.status === 200) {
      const data = response?.data?.data?.map((item) => ({
        label: item?.design_title,
        value: item?.id,
      }));
      setOptions(data);
      if (state?.title === "edit" && state?.designs !== null) {
        const res = data?.filter((item) => {
          const filter = state?.designs
            ?.split(",")
            ?.filter((item1) => item.value === item1);
          if (filter?.length !== 0) {
            return item;
          }
        });
        setDesigns(res);
      }
    }
  };

  const onChangeDesign = (e) => {
    setDesigns(e);
    setErrors({
      ...errors,
      designs: "",
    });
  };

  const CallSetStatusCall = () => {
    setType(state?.title);
    const payload = {
      is_admin: 0,
    };
    if (state?.name !== "") {
      payload.name = state?.name;
    }
    if (state?.email !== "") {
      payload.email = state?.email;
    }
    if (state?.is_admin !== "") {
      payload.is_admin = state?.is_admin;
    }
    if (state?.password !== "") {
      payload.password = state?.password;
    }
    setFormData({
      ...payload,
      is_admin: 0,
      password: "",
    });
  };

  const validate = () => {
    let value = true;
    if (validateEmail(formData?.email)) {
      value = false;
      errors.email = "Enter valid email";
    }
    if (formData?.email === "") {
      value = false;
      errors.email = "Enter email";
    }
    if (formData.email) {
      const capitalLettersRegex = /[A-Z]/;
      const spaceRegex = /\s/;
      const hasCapitalLetters = capitalLettersRegex.test(formData.email);
      const hasSpaces = spaceRegex.test(formData.email);
      const res = !hasCapitalLetters || hasSpaces;
      if (!res) {
        errors.email = "Enter valid email";
        value=false;
      }
    }

    if (formData?.name === "") {
      value = false;
      errors.name = "Enter name";
    }
    if (id.length < 20) {
      if (checkPasswordValidity(formData?.password)) {
        value = false;
        errors.password =
          "Entered password must be combination of special characters,numbers and small and capital alphabets";
      }
      if (formData?.password === "") {
        value = false;
        errors.password = "Enter password";
      }
    }
    if (designs?.length === 0 && showDesign) {
      value = false;
      errors.designs = "Select design";
    }
    setErrors({
      ...errors,
      email: errors?.email,
      is_admin: errors?.is_admin,
      name: errors?.name,
      password: errors?.password,
      designs: errors?.designs,
    });
    return value;
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (validate()) {
      const payload = {
        name: formData?.name,
        email: formData?.email,
        password: formData?.password,
        is_admin: formData?.is_admin,
      };
      // If is_admin is user then only pass the design
      if (payload?.is_admin !== "1") {
        if (designs?.length !== 0) {
          const list = designs?.map((item) => item.value).toString();
          payload.designs = list;
        }
      }
      const response = await postRequestcall(ADD_USER, payload, access_token);
      if (response?.status === 200) {
        dispatch(
          ShowToast({
            description: "User added successfull",
            type: "success",
          })
        );
        navigate("/Users");
      } else {
        if (response?.status === 400) {
          let errorValue = "Already registered email id";
          let error = "";
          if (typeof errorValue === "string" || errorValue instanceof String) {
            error = errorValue;
          } else {
            if (errorValue?.designs) {
              error = errorValue?.designs;
            }
            if (errorValue?.name) {
              error = errorValue?.name;
            }
            if (errorValue?.email) {
              error = errorValue?.email;
            }
            if (errorValue?.password) {
              error = errorValue?.password;
            }
          }
          dispatch(
            ShowToast({
              description: error,
              type: "error",
            })
          );
        }
        if (response?.status === 403) {
          dispatch(setCallLogout());
        }
      }
    }
  }

  const onChangeHandler = (e) => {
    if (e.target.name === "password") {
      if (e.target.value.includes(" ")) {
        // If the password contains a space, show an error or handle it accordingly.
        // You can set an error state or display a message to the user.
        // For example:
        setErrors({ ...errors, password: "Password cannot contain spaces." });
        return;
      } else {
        // If there are no spaces in the password, proceed with updating the form data.
        setErrors({ ...errors, password: "" }); // Clear any previous error message.
        setFormData((data) => ({ ...data, password: e.target.value }));
      }
    }
    if (e.target.name === "is_admin") {
      // If User type admin then hide the selection of design
      if (e.target.value === "1") {
        setShowdesign(false);
      } else {
        setShowdesign(true);
      }
    }

    // if (e.target.name === "email") {
    //   const lowercaseEmail = e.target.value.replace(/\s/g, "").toLowerCase();
    //   // Check if the lowercase email is the same as the original value to ensure it contains no spaces or uppercase letters.
    //   if (e.target.value !== lowercaseEmail) {
    //     // If the email contains spaces or uppercase letters, show an error or handle it accordingly.
    //     // You can set an error state or display a message to the user.
    //     // For example:
    //     setErrors({ ...errors, email: "Enter valid email" });
    //     // Don't update formData when the email contains spaces or uppercase letters.
    //     return;
    //   } else {
    //     // If the email is in the correct format, clear any previous error message and update the formData.
    //     setErrors({ ...errors, email: "" });
    //     setFormData((data) => ({
    //       ...data,
    //       [e.target.name]: lowercaseEmail,
    //     }));
    //   }
    // } else {
    //   // Update formData for all other fields.
    //   setFormData((data) => ({ ...data, [e.target.name]: e.target.value }));
    // }

    setFormData((data) => ({ ...data, [e?.target?.name]: e.target.value }));
    setErrors({ ...errors, [e?.target?.name]: "" });
  };

  const UpdatePayload = () => {
    const payload = {
      user_id: state?.id,
    };
    console.log("payload", payload?.user_id);
    if (formData?.name !== "") {
      payload.name = formData?.name;
    }
    if (formData?.email !== "") {
      payload.email = formData?.email;
    }
    if (formData?.is_admin !== "") {
      payload.is_admin = formData?.is_admin;
    }
    // if (formData?.password !== "") {
    //   payload.password = formData?.password;
    // }
    return payload;
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (validate()) {
      const payload = UpdatePayload();
      // If is_admin is user then only pass the design
      if (payload?.is_admin !== "1") {
        if (designs?.length !== 0) {
          const list = designs?.map((item) => item.value).toString();
          payload.designs = list;
        }
      }
      const response = await postRequestcall(
        UPDATE_USER,
        payload,
        access_token
      );
      if (response?.status === 200) {
        dispatch(
          ShowToast({
            description: "User updated successfully",
            type: "success",
          })
        );
        navigate(location.pathname, {});
      } else {
        if (response?.response?.status === 400) {
          let errorValue = response?.data?.error;
          let error = "";
          if (typeof errorValue === "string" || errorValue instanceof String) {
            error = errorValue;
          } else {
            if (errorValue?.designs) {
              error = errorValue?.designs;
            }
            if (errorValue?.name) {
              error = errorValue?.name;
            }
            if (errorValue?.email) {
              error = errorValue?.email;
            }
            if (errorValue?.password) {
              error = errorValue?.password;
            }
          }
          dispatch(
            ShowToast({
              description: error,
              type: "error",
            })
          );
        }
        if (response?.response?.status === 403) {
          dispatch(setCallLogout());
        }
      }
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col className="mt-3 mb-4">
          <Row>
            <Col>
              <h4 className="title">{props?.title}</h4>
            </Col>
            <Col>
              <div className="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"dashboard"}>
                      <Icon className={"home"} />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"../"}> Users</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {props?.breadcrumb}
                  </li>
                </ol>
              </div>
            </Col>
          </Row>
          <div className="card mt-3">
            <div className="card-body p-4">
              <form
                id="formAuthentication"
                className="mb-3 row"
                onSubmit={type === "add" ? handleSubmit : handleUpdate}
              >
                <div className="col-sm-6">
                  <InputGroup
                    label="Name"
                    require={type === "edit" ? false : true}
                    placeholder="Enter name"
                    name={"name"}
                    onChange={onChangeHandler}
                    value={formData?.name}
                    errors={errors?.name}
                  />
                </div>
                <div className="col-sm-6">
                  <InputGroup
                    label="Email"
                    require={type === "edit" ? false : true}
                    placeholder="Enter email"
                    name={"email"}
                    onChange={onChangeHandler}
                    value={formData?.email}
                    errors={errors?.email}
                  />
                </div>
                <div className="col-sm-6">
                  <InputGroup
                    label="Password"
                    require={type === "edit" ? false : true}
                    leftIcon="icon_password"
                    placeholder="Enter password"
                    type={PassType ? "password" : "text"}
                    name={"password"}
                    onChange={onChangeHandler}
                    value={formData?.password}
                    errors={errors?.password}
                    rightIcon={PassType ? "eyeslash" : "eye"}
                    rightButton={true}
                    rightClick={() => setPassType(!PassType)}
                  />
                </div>
                <div className="col-sm-6 common-form mb-3">
                  <label htmlFor="is_admin" className="form-label">
                    User Type
                  </label>
                  <Form.Select
                    id={"is_admin"}
                    value={formData?.is_admin}
                    onChange={onChangeHandler}
                    placeholder={"is_admin"}
                    name={"is_admin"}
                  >
                    <option value={0}>User</option>
                    <option value={1}>Admin</option>
                  </Form.Select>
                  <span className="text-danger">{errors?.is_admin}</span>
                </div>
                <div className="col-sm-6 common-form">
                  {showDesign && (
                    <>
                      <ReactSelect
                        label={"Design"}
                        isMulti={true}
                        placeholder={"Select Design"}
                        options={options}
                        value={designs}
                        onChange={(e) => onChangeDesign(e)}
                      />
                      <span className="text-danger">{errors?.designs}</span>
                    </>
                  )}
                </div>
                <div className="ml-auto d-flex">
                  <Button type="submit" className={"button-add ml-auto"}>
                    {props?.title}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

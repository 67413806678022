/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import IconButton from "../../common/IconButton";
import { useNavigate } from "react-router";
import Button from "../../common/Button";
import { setCallBackFileSelect, setStoreSelectViewerDesign,ShowModalConfirm, ShowToast, setUpdatePoints, setLoader, setCallLogout } from "../../../redux/actions";
import {useDispatch,useSelector} from "react-redux";
import Nodatafound from "../../common/Nodatafound";
import { postRequestcall } from "../../../api/postRequest";
import { DESIGN_ADD,DESIGN_DELETE } from "../../../constant/apiurl";
import { callDesigndetail, CallGetDesignList } from "../../../redux/actions/utils";
import PaginationPage from "../../common/PaginationPage";

export default function SvglistPage () {

const navigate = useNavigate();
const dispatch = useDispatch();
const {designlist,access_token} = useSelector((state)=>state?.allReducers)

  useEffect(()=>{
     callSvglist(1);
  },[])

  const callSvglist = async (current_page) => {
    dispatch(await CallGetDesignList("all", current_page));
  }

  // It is for design edit 
  const navigateEdit = async (e,id) => {
    e.preventDefault();
    dispatch(setLoader(true));
    let getDesigndetailResponse = await callDesigndetail(access_token,id); // Get design detail api
    dispatch(setStoreSelectViewerDesign(getDesigndetailResponse));
    dispatch(setCallBackFileSelect({data:[]}));
    dispatch(setUpdatePoints([]));
    dispatch(setLoader(false));
    navigate(`edit/${id}`);
  }

  // It is for delete the design
  const deleteDesign = (e,item) => {
      e.preventDefault();
      dispatch(ShowModalConfirm({
        Title:"Are you sure?",
        show:true,
        Description:" Are you really want to delete this design?",
        id:"",
        callBackModal:()=>callDeletesvg(item?.id),
        ButtonSuccess:"Delete Design"
    }))
  }

  // It is used for design delete
  const callDeletesvg = async (id) => {
    let getDesignid = { design_id:id }
    const response = await postRequestcall(DESIGN_DELETE,getDesignid,access_token); // Call delete design api
     if(response?.status === 200) {
        dispatch(ShowToast({
            description:"Design is deleted successfully",
            show:true,
            type:"success"
        }))
        callSvglist(1);
     }else if (response?.status === 400) {
        dispatch(ShowToast({
            description:response?.data?.error,
            show:true,
            type:"error"
        }))
     }
     else if (response?.status === 403) {
        navigate("/");
        dispatch(setCallLogout());
     }
  }

  const designImagechange = async (e) => {
    if(e.target.files[0].type === "image/svg+xml") {
        let formData = new FormData();
        formData.append("file", e.target.files[0]);
        dispatch(setLoader(true))
        let getResponse  = await postRequestcall(DESIGN_ADD,formData,access_token,true);
        if(getResponse?.status === 200) {
            dispatch(setStoreSelectViewerDesign(getResponse?.data?.data));
            dispatch(setCallBackFileSelect({data:[]}))
            dispatch(setUpdatePoints([]));
            //  navigate("add")
            handleRedirect(getResponse?.data?.data?.id)
        }
        } else {
        dispatch(setLoader(false))
        dispatch(ShowToast({
            title:"Please upload svg file",
            description:"",
            show:true,
            type:"success",
          }))
    }
 }
 
 const navigateView = (e,id) => {
    e.preventDefault();
    var timestamp = new Date().getTime();
    navigate(`/viewer/${id}`,{ state: { obj: timestamp } });
 }
 const handleRedirect = (idValue) => {
    // Replace 'target-page.html' with the path to your HTML page.
    const targetUrl = `${process.env.REACT_APP_EDITOR}?id=${idValue}&access_token=${access_token}`;
    console.log("target url",targetUrl)
    // history.push(targetUrl);
    window.location.href = targetUrl;
  };

 return (
    <Row>
    <Col className='mt-3'>
        <Card className="p-4">
            <Card.Header className='p-0'>
                <Row>
                    <Col sm={12} xl={6}>
                        <div className='left-card'>
                            <h5 className='title mb-4'>Design list</h5>
                        </div>
                    </Col>
                    <Col sm={12} xl={6}>
                        <div className='right-card gap-3'>
                            <input
                             type="file"
                             id="autocad_img"
                             style={{ display: "none" }}
                             onChange={(e) => designImagechange(e)}
                             accept='.svg'
                           />
                            <Button onClick={() => document.getElementById("autocad_img").click()} btntype="button-add btn-sm btn-x-lg">
                                + Add Design
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body className="p-0">
                <h5 className="subtitle">Result</h5>
            </Card.Body>
            {designlist?.data?.length === 0 ? 
                (<Nodatafound title={"No design found please add"}></Nodatafound>):(
                    <div className="table-responsive fade show">
                    <table className="table table-responsive-sm table-hover">
                        <thead>
                            <tr>
                                <th>Sr.No</th>
                                <th>Title</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                designlist?.data?.map((item,index)=> (
                                    <tr key={index}>
                                    <th scope="row">{index+1}</th>
                                    <td>{item?.design_title}</td>
                                    <td className='gap-2 text-center'>
                                        <IconButton icon='eye' onClick={(e)=>navigateView(e,item?.id)}/>
                                        {/* <IconButton icon='edit' onClick={(e)=>navigateEdit(e,item?.id)}/> */}
                                        <IconButton icon='edit' onClick={(e)=>handleRedirect(item?.id)}/>
                                      

                                        
                                        <IconButton icon='trash' className={'danger'} onClick={(e)=>deleteDesign(e,item)}/>
                                    </td>
                                </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                )
            }
        </Card>
         <PaginationPage 
           handleChange={callSvglist}
           nopagination={false}
           pagination={designlist?.pagination}
           data={designlist?.data}
         />
    </Col>
</Row>
 )

}

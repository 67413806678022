import React from "react";

function Spinnerloader () {
 return (
        <div className="opacity-spinner">
        <div className="mys-spinner mys-spinner-centered  mys-spinner-large">
          <div className="mys-spinner-double-bounce1  "></div>
          <div className="mys-spinner-double-bounce2  "></div>
          <p className="mys-spinner-txt">Loading...</p>
       </div>
       </div>
      
 )
}
export default Spinnerloader;
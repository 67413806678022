/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Button from "../../common/Button";
import Icon from "../../common/Icon";
import IconButton from "../../common/IconButton";
import InputGroup from "../../common/InputGroup";
import Texteditor from "../../common/TextEditor";
import CardItem from "./CardItem";
import { Link, useLocation } from "react-router-dom";
import { postRequestcall } from "../../../api/postRequest";
import {
  DESIGN_LIST,
  EQUIPMENT_ADD,
  EQUIPMENT_UPDATE,
} from "../../../constant/apiurl";
import { ShowToast, setShowEquipmentmodal } from "../../../redux/actions";
import Selectlist from "../../common/Selectlist";
import { getRequestcall } from "../../../api/getRequest";

const Breadcrumb = (props) => {
  const checkUrl = window.location.pathname.split("/");
  return (
    <Row className={"m-0"}>
      <Col className="col-6 page-title">
        <h4 className=" title text-capitalize breadcrumb-title">
          {props?.title}
        </h4>
      </Col>
      {checkUrl[1] !== "design" && (
        <Col className="col-6 page-title">
          <div className="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/dashboard"}>
                  <Icon className={"home"} />
                </Link>
              </li>
              <li className="breadcrumb-item active text-capitalize">
                <Link to={`/equipment`}>equipment</Link>
              </li>
              <li className="breadcrumb-item active text-capitalize">
                {props?.breadcrumb}
              </li>
            </ol>
          </div>
        </Col>
      )}
    </Row>
  );
};

export default function AddEquipmentPage(props) {
  const equipmentList = useSelector((state) => state?.allReducers?.equipment);
  const access_token = useSelector((state) => state?.allReducers?.access_token);
  const selectDesign = useSelector((state) => state?.allReducers?.selectDesign);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const id = window.location.href
    .substring(window.location.href.lastIndexOf("/") + 1)
    .toString();
  const checkUrl = window.location.pathname.split("/");
  const [formData, setFormData] = useState({
    equipment_title: "",
    equipment_description: "",
    equipment_meta: [],
    is_active: 1,
    key: "",
    value: "",
  });
  const [errors, setErrors] = useState({
    equipment_title: "",
    equipment_description: "",
    key: "",
    value: "",
    select_design: "",
  });

  const [showlist, setShowlist] = useState(false);
  const [designlist, setDesignlist] = useState([]);
  const [selectedDesign, setSelecteddesign] = useState([]);
  const [position, setPosition] = useState();

  useEffect(() => {
    if (props?.breadcrumb === "add") {
    } else if (props?.breadcrumb === "update" && state?.item) {
      callEditDetails();
    } else if (state?.item === undefined && props?.breadcrumb === "update") {
      navigate("/equipment");
    }
    callDesignlist();
  }, []);
  // Attach the event listener when the component mounts
  useEffect(() => {
    document.addEventListener("click", () => {
      setShowlist(false);
    });
  }, []);

  // It is used for design list
  const callDesignlist = async () => {
    let url = DESIGN_LIST + `/all/5000/1`;
    let response = await getRequestcall(url, access_token); // Call design list
    if (response?.status === 200) {
      let getList = response?.data?.data?.map((item) => {
        return {
          key: item?.id,
          value: item?.design_title,
        };
      });
      getSelectedDesign(getList);
    }
  };

  const getSelectedDesign = (getList) => {
    if (
      state?.item?.selected_designs !== null &&
      typeof state?.item?.selected_designs !== "undefined"
    ) {
      // Show selected design
      let getValue = state?.item?.selected_designs.split(",");
      if (getList?.length > 0) {
        let getSelectedlist = getList.filter((val) => {
          return getValue.find((val2) => {
            return val.key === val2;
          });
        });
        if (getSelectedlist?.length > 0) {
          // Selected Remove From Original List
          let getUpdatedlist = getList.filter((val) => {
            return !getValue.find((val2) => {
              return val.key === val2;
            });
          });
          setSelecteddesign(getSelectedlist);
          setDesignlist(getUpdatedlist);
        }
      }
    } else {
      setDesignlist(getList);
    }
  };

  const callEditDetails = () => {
    setFormData({
      ...state?.item,
      equipment_meta: state?.item?.equipment_meta
        ? state?.item?.equipment_meta
        : [],
      is_active: state?.item?.is_active ? 1 : 0,
      key: "",
      value: "",
    });
  };

  const validation = () => {
    let val = true;
    if (formData?.equipment_title === "") {
      errors.equipment_title = "Enter title";
      val = false;
    }
    if (formData?.equipment_description === "<p><br></p>") {
      errors.equipment_description = "Enter description";
      val = false;
    }
    // console.log("props?.designhide", props?.designHide);
    if (props?.designHide === false) {
      if (selectedDesign?.length === 0) {
        errors.select_design = "Select design";
        val = false;
      }
      if (designlist?.length === 0 && selectedDesign?.length === 0) {
        if (checkUrl[1] !== "design") {
          // If no design found getting then show message Please add design first
          showErromessage("Please add design first"); // If design not found then show error message
        }
      }
    }

    setErrors({
      equipment_title: errors?.equipment_title,
      equipment_description: errors?.equipment_description,
      select_design: props?.designHide === false ? errors?.select_design : "",
    });
    return val;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };

  const handleEditorChange = (e) => {
    setFormData((state) => ({ ...state, equipment_description: e }));
    setErrors((state) => ({ ...state, equipment_description: "" }));
  };

  const UpdateStatePayload = () => {
    const payload = {};
    if (formData?.equipment_title) {
      payload.equipment_title = formData?.equipment_title;
    }
    if (formData?.equipment_description) {
      payload.equipment_description = formData?.equipment_description;
    }
    if (formData?.equipment_meta) {
      payload.equipment_meta = JSON.stringify(formData?.equipment_meta);
    }
    if (formData?.is_active) {
      payload.is_active = formData?.is_active;
    }
    if (props?.designHide === false) {
      if (selectedDesign?.length > 0) {
        payload.selected_designs = selectedDesign
          ?.map((item) => {
            return item?.key;
          })
          .join(",");
      }
    } else {
      payload.selected_designs = selectDesign?.id;
    }
    return payload;
  };

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    if (validation()) {
      const payload = UpdateStatePayload();
      if (props?.breadcrumb === "update") {
        payload.equipment_id = id;
        let response = await postRequestcall(
          EQUIPMENT_UPDATE,
          payload,
          access_token
        );
        if (response?.status === 200) {
          dispatch(
            ShowToast({
              description: "Equipment updated successfully",
              type: "success",
            })
          );

          navigate("/equipment");
        } else if (response?.status === 400) {
          showErromessage(response?.data?.error);
        }
      } else {
        let response = await postRequestcall(
          EQUIPMENT_ADD,
          payload,
          access_token
        );
        if (response?.status === 200) {
          dispatch(
            ShowToast({
              description: "Equipment added successfully",
              type: "success",
            })
          );

          if (checkUrl[1] === "design") {
            // It is used for design
            let getObject = {
              key: response?.data?.data?.id,
              value: response?.data?.data?.equipment_title,
            };
            let getSelectedequ = [...props?.selectedEquipment];
            getSelectedequ.push(getObject);
            props?.setSelectedequipment(getSelectedequ);
            dispatch(setShowEquipmentmodal(false));
          } else {
            navigate("/equipment");
          }
        } else if (response?.status === 400) {
          showErromessage(response?.data?.error);
        }
      }
    }
  };

  const showErromessage = (error) => {
    dispatch(
      ShowToast({
        description: error,
        show: true,
        type: "error",
      })
    );
  };

  const validateItems = () => {
    let val = true;
    if (formData?.key === "") {
      val = false;
      errors.key = "Enter key";
    }
    if (formData?.value === "") {
      val = false;
      errors.value = "Enter value";
    }
    setErrors({
      ...errors,
      key: errors?.key,
      value: errors?.value,
    });
    return val;
  };

  const CallAddItems = () => {
    if (validateItems()) {
      const Data =
        formData?.equipment_meta?.length === 0 ? [] : formData?.equipment_meta;
      Data?.push({
        key: formData?.key,
        value: formData?.value,
      });
      setFormData((state) => ({
        ...state,
        equipment_meta: Data,
        key: "",
        value: "",
      }));
    }
  };

  const onDelete = (e) => {
    setFormData((state) => ({
      ...state,
      equipment_meta: formData?.equipment_meta?.filter(
        (item, index) => index !== e
      ),
    }));
  };

  const onSelectDesign = (e, select, index) => {
    e.preventDefault();
    let getItem = [...selectedDesign];
    setPosition(index);
    getItem.push(select);
    setSelecteddesign(getItem);
    let getList = designlist?.filter(
      (item, index) => item?.key !== select?.key
    );
    setDesignlist(getList);
    setShowlist(false);
    setErrors({
      ...errors,
      select_design: "",
    });
  };

  const removeSelecteddesign = (e, item) => {
    e.stopPropagation();
    e.preventDefault();
    let updateSelected = selectedDesign?.filter(
      (select) => select?.key !== item?.key
    );
    setSelecteddesign(updateSelected);
    let updateDesign = [...designlist]; // After remove add into the list to previoues position
    updateDesign.splice(position, 0, item);
    setDesignlist(updateDesign);
  };

  return (
    <Row>
      <Col className={checkUrl[1] === "design" ? "mb-3" : "mt-3"}>
        <Card.Header
          className=""
          style={{ padding: checkUrl[1] === "design" ? "" : "" }}
        >
          <Breadcrumb title={props?.title} breadcrumb={props?.breadcrumb} />
        </Card.Header>
        <Card
          className="overflow p-4"
          style={{ padding: checkUrl[1] === "design" ? "" : "" }}
        >
          <Card.Body className="p-0">
            <Form className="row " onSubmit={onHandleSubmit}>
              {props?.designHide === false && (
                <Col className="col-12">
                  <Form.Group className="mb-2">
                    <Form.Label>Select Design</Form.Label>
                    <span className="text-danger"> *</span>
                    <Selectlist
                      placeholder={"Select design"}
                      type="design"
                      list={designlist}
                      groupItemClick={onSelectDesign}
                      showlist={showlist}
                      setShowlist={setShowlist}
                      selectedItem={selectedDesign}
                      removeItem={removeSelecteddesign}
                    />
                    <small className="text-danger">
                      {errors?.select_design}
                    </small>
                  </Form.Group>
                </Col>
              )}
              <Col className="col-12">
                <InputGroup
                  name="equipment_title"
                  placeholder="Enter Title"
                  label="Title"
                  value={formData?.equipment_title}
                  onChange={handleChange}
                  require
                  errors={errors?.equipment_title}
                />
              </Col>
              <Col className="col-12 mb-3">
                <Texteditor
                  name="equipment_description"
                  placeholder="Enter Description hear..."
                  onChange={handleEditorChange}
                  value={formData?.equipment_description}
                  label="Description"
                  errors={errors?.equipment_description}
                  require
                />
              </Col>
              <Row>
                <div className="d-flex align-items-end gap-3 mb-3">
                  <InputGroup
                    name="key"
                    placeholder="Enter Key"
                    label="Key"
                    formClass={"mb-0 w-auto"}
                    onChange={handleChange}
                    showTextError={false}
                    errors={errors.key}
                    value={formData.key}
                  />
                  <InputGroup
                    name="value"
                    placeholder="Enter Value"
                    label="Value"
                    formClass={"mb-0 w-auto"}
                    onChange={handleChange}
                    errors={errors.value}
                    showTextError={false}
                    value={formData.value}
                  />
                  <div className="action-item ml-2 w-auto">
                    <IconButton
                      icon="plus"
                      className={"add-btn-icon"}
                      onClick={CallAddItems}
                    />
                  </div>
                </div>
                {formData?.equipment_meta?.map((item, index) => (
                  <div className="w-auto" key={index.toString()}>
                    <CardItem
                      data={formData?.equipment_meta}
                      key={index?.toString()}
                      onDelete={onDelete}
                      item={item}
                      index={index}
                    />
                  </div>
                ))}
              </Row>
              <Col className="col-12 d-flex justify-content-end mt-3">
                <Button
                  type="submit"
                  block={false}
                  className={"button-add text-capitalize"}
                >
                  {props?.title}
                </Button>
              </Col>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

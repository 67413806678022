/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setCallLogout,
  setClearviewerDesign,
  SetShowMenu,
  setUpdateGetProfile,
} from "../../redux/actions";
import Icon from "../common/Icon";
import { Link, useNavigate } from "react-router-dom";
import { getRequestcall } from "../../api/getRequest";
import { LOGOUT, USER_GET } from "../../constant/apiurl";
import LogoImages from "../../assets/img/323logo.png";
import ReactSelect from "../common/ReactSelect";
import { ShowModalConfirm, ShowToast } from '../../redux/actions'

export default function Header() {

  let getUrl = window.location.pathname.split("/");
  const { ShowMenu, access_token, currentUser, selectDesign,selectDesignlist,designOption } = useSelector((state) => state?.allReducers);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    callUserProfile();
  }, []);

  const callUserProfile = async () => {
    const response = await getRequestcall(USER_GET, access_token);
    if (response?.status === 200) {
      dispatch(setUpdateGetProfile(response?.data?.data));
    } else if (response?.status === 403) {
      dispatch(setCallLogout());
    }
  };
  const callLogOutFun = () =>{
    dispatch(ShowModalConfirm({
        Title:"Are you sure?",
        show:true,
        Description:" Are you really want to log out ?",
        callBackModal:()=>callLogout(),
        ButtonSuccess:"Log Out"
    }))
}
  const callLogout = async () => {
    const response = await getRequestcall(LOGOUT, access_token);
    if (response?.status === 200) {
      navigate("/");
      dispatch(setCallLogout());
    } else if (response?.status === 403) {
      navigate("/");
      dispatch(setCallLogout());
    }
  };

 // Navigate to Viewer 
  const showViewer = () => {
    if(typeof selectDesign === "undefined") {
        navigate("/viewer");
    } else {
      if(getUrl[1] === "viewer") {
         if(currentUser?.is_admin) {
          navigate("/dashboard");
         }else{
          navigate("/profile");
         }
      } else {
        navigate(`/viewer/${selectDesign?.id}`);
      }
      // Select design clear because when we remove the pointer from current designer and if we see that design in viewer some time few second that pointer will show
      // So the selectDesign must be clear before going to viewer
      dispatch(setClearviewerDesign());
    }
  };
  
  const callOnSelect = async (list, e) => {
    if (designOption?.value !== list?.value) {
      var timestamp = new Date().getTime();
      navigate(`/viewer/${list?.value}`,{ state: { obj: timestamp } });
    }
  };

  return (
    <header className="header-top">
      <nav className={`navbar navbar-light `}>
        <div className="navbar-left">
          <div className="logo-area">
            <img alt="" src={LogoImages} onClick={() => showViewer()} />
             {
              currentUser?.is_admin && (
                getUrl[1] !== "viewer" && (
                  <span
                      onClick={() => dispatch(SetShowMenu(!ShowMenu))}
                      className="sidebar-toggle"
                  >
                  <Icon className="menu_icon" />
                  </span>
                )
              )
             }
          </div>
          {getUrl[1] === "viewer" && (
           <div className="header-select" style={{paddingLeft:"1rem"}}>
             <ReactSelect
              options={selectDesignlist}
              formClass={"mb-0"}
              value={selectDesignlist?.length !== 0 ? designOption : []}
              onChange={callOnSelect}
              placeholder={"Select Design"}
            />
           </div>
         )}
        </div>
        <div className="navbar-right">
          <div className="navbar-right__menu">
            <div className="nav-author">
              <Dropdown className="user-menu">
                <Dropdown.Toggle id="dropdown-button-dark-example1">
                  <Icon className={"users"} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="menu_icon">
                  <Dropdown.ItemText>
                    <span className="user-icon"><Icon className={"users"} /></span>
                    <div>
                      <h6 className="mb-0">{currentUser?.name}</h6>
                      {
                    currentUser?.is_admin ? ( <span>Admin</span> ) : (
                      <span>User</span>
                    )}
                    </div>
                  </Dropdown.ItemText>
                  <Dropdown.Divider />
                  {
                    currentUser?.is_admin && (
                      <Dropdown.Item as={Link} to={"/dashboard"}>
                      <Icon className="dashboard" />
                         Dashboard
                      </Dropdown.Item>
                    )
                   }
                  <Dropdown.Item as={Link} to={"/profile"}>
                    <Icon className="usermenu" />
                         Profile
                   </Dropdown.Item>
                  <Dropdown.Item onClick={() => callLogOutFun()}>
                    <Icon className="logout" />
                    Log Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}


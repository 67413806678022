
import React from 'react';
import { useSelector } from 'react-redux';
import {  Outlet  } from "react-router"
import { BrowserRouter, Route, Navigate, Routes } from 'react-router-dom';
import ConfirmModal from './components/common/ConfirmModal';
import CustomeToaster from './components/common/CustomeToaster';
import Icon from './components/common/Icon';
import SvgviewerPage from './components/svgviewer/SvgviewerPage';
import DashboardIndex from './components/dashboardpanel/DashboardIndex';
import AddEquipmentPage from './components/dashboardpanel/equipment/AddEquipmentPage';
import EquipmentPage from './components/dashboardpanel/equipment/EquipmentPage';
import HomePage from './components/dashboardpanel/home/HomePage';
import ReactMediaLibraryWrapper from './components/dashboardpanel/media/ReactMediaLibraryWrapper';
import ProfilePage from './components/dashboardpanel/profile/ProfilePage';
import AddsvgPage from './components/dashboardpanel/svg/AddsvgPage';
import SvglistPage from './components/dashboardpanel/svg/SvglistPage';
import ForgotPage from './components/forgotscreen/ForgotPage';
import LoginPage from './components/loginscreen/LoginPage';
import axios from 'axios';
import ResetpasswordPage from './components/resetpassword/ResetpasswordPage';
import Userslistpage from './components/dashboardpanel/users/UsersListPage';
import AddUsers from './components/dashboardpanel/users/AddUsers';
import Header from './components/header/HeaderPage';
import Spinnerloader from './components/common/Spinnerloader';
import { useDispatch } from 'react-redux';
import { setLoader } from './redux/actions';

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export const SidebarList = [
  {
    title:"Dashboard",
    icon:"dashboard",
    to:"/dashboard",
    Element:<HomePage/>,
    sidebar:true,
    children:[]
  },
  {
    title:"Equipment",
    icon:"equipment",
    to:"/equipment",
    sidebar:true,
    Element:<EquipmentPage/>,
    children:[
      {
          to:"add",
          title:"Add Equipment",
          Element:<AddEquipmentPage designHide={false} title={"Add Equipments"} breadcrumb={"add"}/>
      },
      {
          to:"edit/:id",
          title:"Edit Equipment",
          Element:<AddEquipmentPage designHide={false} title={"Update Equipments"} breadcrumb={"update"}/>
      }
    ],
  },
  {
    title:"Design",
    icon:"svg1",
    to:"/design",
    sidebar:true,
    Element:<SvglistPage/>,
    children:[
      {
        to:"add",
        title:"Add Design",
        Element:<AddsvgPage title={"Add Svg"} breadcrumb={"Add"}/>
      },{
          to:"edit/:id",
          title:"Edit Design",
          Element:<AddsvgPage title={"Edit Svg"} breadcrumb={"update"}/>
      }
    ]
  },
  {
    title:"Media",
    icon:"media",
    to:"/Media",
    sidebar:false,
    Element:<ReactMediaLibraryWrapper/>,
    children:[]
  },
  {
    title:"Users",
    icon:"users_1",
    to:"/Users",
    sidebar:true,
    Element:<Userslistpage/>,
    children:[
        {
          title:"Users",
          icon:"userlist",
          to:"add",
          Element:<AddUsers title={"Add User"} breadcrumb={"Add"} type={"add"} />
      },
      {
          title:"Users",
          icon:"userlist",
          to:"edit/:id",
          Element:<AddUsers title={"Update User"} breadcrumb={"Update"} type={"edit"}/>
      }
    ]
  },
  {
    title:"Profile",
    icon:"usermenu",
    to:"/profile",
    sidebar:true,
    Element:<ProfilePage/>,
    children:[]
  }
];

function App() {
const dispatch=useDispatch()
  const {access_token,currentUser,loader} = useSelector((state)=>state?.allReducers);
  
  return (
    <div className="App" onLoad={()=>dispatch(setLoader(false))}>
      <BrowserRouter>
         {access_token && <Header /> }
          <Routes>
            {!access_token ?
                <React.Fragment>
                  <Route path="/" element={<Navigate to={"/login"} replace={true} />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/forgot" element={<ForgotPage />} />
                  <Route path="/reset-password" element={<ResetpasswordPage/>} />
                  <Route path="*"  exact element={<Navigate to={"/login"} replace={true} />} />
                 </React.Fragment>
                :
                <React.Fragment>
                   {
                     currentUser?.is_admin ? (
                      <Route exact path="/" element={<DashboardIndex />}>
                      <Route index exact element={<Navigate to={"dashboard"} />} />
                       {SidebarList?.map(({Element, to, children}, index)=> Element && (
                          <Route path={to} key={index?.toString()}  exact element={<Outlet/>} >
                            <Route index exact element={Element} />
                             {children?.map((item, index1)=>(
                             <Route path={item?.to} key={index1?.toString()} exact element={item?.Element} />
                          ))}
                         </Route>
                      ))}  
                      <Route path="*" exact element={<Navigate to="dashboard" replace={true} />} />
                     </Route>
                     ) : (
                        <>
                       <Route path="/profile" exact element={<ProfilePage />} />
                       <Route path="*" exact element={<Navigate to="/viewer/*" replace={true} />} />
                       </>
                     )
                   }
                <Route path="/viewer/:id" exact element={<SvgviewerPage />} />
              </React.Fragment>
            }
          </Routes>
      </BrowserRouter>
      <div className="tap-top">
        <Icon className={"arrowtop"} />
      </div>
      <CustomeToaster/>
      <ConfirmModal/>
      {loader && (
          <Spinnerloader />
      )} 
    </div>
  );
}

export default App;

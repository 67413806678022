/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { ButtonPage } from './Button';
import InputGroup from './InputGroup';
import { useSelector } from 'react-redux';

export default function EditTitlemodal(props) {

    const [title, setTitle] = useState("");
    const [titleErr, setTitleEr] = useState("");
    const { allpoints } = useSelector((state) => state?.allReducers);

    useEffect(() => {
        setTitle(props?.designInfo);
    }, [props?.designInfo])

    const closeEdittile = () => {
        setTitle("");
        props?.setShowtitle(false);
    }

    const saveTitle = () => {
        if (title !== "") {
            props?.setShowtitle(false);
            props?.setDesignInfo(title);
            props?.updateDesignfile(allpoints,title);
            setTitle("");
        } else {
            setTitleEr("Enter design title");
        }
    }

    const onChangetitle = (e) => {
        e.preventDefault();
        setTitle(e.target.value);
        setTitleEr("");
    }

    return (
        <Modal centered={true} show={props?.showTitle} className="edit-title">
            <Modal.Body className="pb-0">
                <InputGroup
                    placeholder='Enter design title'
                    label='Design Title'
                    value={title}
                    errors={titleErr}
                    onChange={(e) => onChangetitle(e)}
                />
            </Modal.Body>
            <div className='swal-footer pb-2 pt-0'>
                <ButtonPage className={"swal-button swal-button--cancel"} onClick={() => closeEdittile()}>
                    Cancel
                </ButtonPage>
                <ButtonPage className={"button-add swal-button swal-button--confirm swal-button--danger mx-0"} onClick={() => saveTitle()}>
                    Save
                </ButtonPage>
            </div>
        </Modal>
    )
}

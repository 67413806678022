/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setDesignoption,
  setDesignoptionslist,
  setFilePreview,
  setLoader,
  setStoreSelectPointer,
  setStoreSelectViewerDesign,
  ShowToast,
} from "../../redux/actions";
import Icon from "../common/Icon";
import ViewerSideBar from "./ViewerSideBar";
import { useLocation, useParams } from "react-router-dom";
import {
  DESIGN_GET,
  DESIGN_LIST,
  EQUIPMENT_GET,
  FILES_GET,
} from "../../constant/apiurl";
import { getRequestcall } from "../../api/getRequest";
import { FilePreview } from "../common/FilePreviewPopup";
import leftArrow from "../../assets/img/left_arrow.png";
import $ from "jquery";

export default function SvgviewerPage(props) {
 
  const { access_token, selectDesign, selectDesignlist } = useSelector(
    (state) => state?.allReducers
  );

  const [ShowEquipment, setShowEquipment] = useState(false);
  const [hover, setHover] = useState(null);
  const [equipmentMeta, setEquipmentmeta] = useState([]);
  const [displayEquipment, setDisplayequipment] = useState(true);
  // const [showResult,setShowresult] = useState(false);
  const infoReference = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const param = useParams();
  let getDesignid = window.location.pathname.split("/").pop();
  var allStates = $("svg > *");
  let panning = false;
  let scale = 1;
  let pointX = 0;
  let pointY = 0;
  let start = { x: 0, y: 0 };

  const [tabState, setTabState] = useState("");

  useEffect(() => {
    callViewerDesign();
  }, [param?.id]);

  allStates.off("mouseover").on("mouseover", function (e) {
    e.preventDefault();
    let getCurrent = selectDesign?.design_description?.allPoints?.filter(
      (item) => item?.id === e?.currentTarget?.id.toString()
    );
    if (getCurrent?.length > 0) {
      $("#" + getCurrent[0].id).attr("data-info", getCurrent[0]?.title);
      $("#info-box").css("display", "block");
      $("#info-box").html($(this).data("info"));
      $("#" + getCurrent[0].id).css("fill", getCurrent[0]?.color);
    } else {
      if (e.currentTarget.tagName === "polygon") {
        $("#info-box").css("display", "none");
        allStates.removeClass("polygon_div");
        $(this).addClass("polygon_div");
      }
    }
  });

  // show the detail of the current polygon
  allStates.off("click").on("click", function (e) {
    let getCurrent = selectDesign?.design_description?.allPoints?.filter(
      (item) => item?.id === e?.currentTarget?.id.toString()
    );
    if (getCurrent?.length > 0) {
      CallSelectPointer(getCurrent[0]);
    }
  });

  allStates.off("mouseleave").on("mouseleave", function (e) {
    e.preventDefault();
    if (e?.currentTarget?.id) {
      const QuerySelect = "#" + e?.currentTarget?.id;
      if ($(QuerySelect)) {
        $(QuerySelect).removeClass("polygon_div");
        $(QuerySelect).removeAttr("style");
      }
    }
  });

  allStates.off("mousemove").on("mousemove", function (e) {
    e.preventDefault();
    $("#info-box").css("top", e.pageY - $("#info-box").height() - 30);
    $("#info-box").css("left", e.pageX - $("#info-box").width() / 2);
  });
  const onClickPointer = (e) => {
    e.preventDefault();
    if (e?.nativeEvent?.target?.id) {
      dispatch(callGetIdPointer(e?.nativeEvent?.target?.id));
    }
  };
  const callGetIdPointer = (id) => (dispatch, getState) => {
    const { selectDesign } = getState()?.allReducers;
    let getPointingID = selectDesign?.design_description?.allPoints?.find(
      (item) => item?.id === id
    );
    if (getPointingID) {
      CallSelectPointer(getPointingID);
    }
  };
  // Calling Viewer design list
  const callViewerDesign = async () => {
    setShowEquipment(false);
    dispatch(setLoader(true));
    const url = `${DESIGN_LIST}/all/${10}/${1}`;
    const response = await getRequestcall(url, access_token);
    if (response?.status === 200) {
      const data = response?.data?.data?.map((item) => ({
        label: item?.design_title,
        value: item?.id,
      }));
      dispatch(setDesignoptionslist(data));
      if (data?.length > 0) {
        // default selected first
        if (getDesignid !== "") {
          let getSelecteddesing = data?.filter(
            (item) => item.value === getDesignid
          );
          if (getSelecteddesing?.length > 0) {
            dispatch(
              setDesignoption({
                label: getSelecteddesing[0]?.label,
                value: getSelecteddesing[0]?.value,
              })
            );
            showViewer(getSelecteddesing[0]?.value);
          } else {
            dispatch(
              setDesignoption({
                label: data[0]?.label,
                value: data[0]?.value,
              })
            );
            showViewer(data[0]?.value);
          }
        }
      } else {
        document.getElementById("leftarrow").style.display = "none";
        dispatch(setLoader(false));
        dispatch(setDesignoptionslist([]));
      }
    } else {
      dispatch(setDesignoptionslist([]));
      dispatch(setLoader(false));
    }
    start = { x: 0, y: 0 };
    scale = 1;
    pointX = 0;
    pointY = 0;
    setTransform(pointX, pointY, "px");
  };

  const showViewer = async (selectDesign) => {
    const url = `${DESIGN_GET}/${selectDesign}`;
    const response = await getRequestcall(url, access_token);
    if (response?.status === 200) {
      dispatch(setStoreSelectViewerDesign(response?.data?.data));
      dispatch(
        setDesignoption({
          label: response?.data?.data?.design_title,
          value: response?.data?.data?.id,
        })
      );
    }
    setTimeout(() => dispatch(setLoader(false)), 1000);
  };

  function setTransform(pointX, pointY, type) {
    document.getElementById(
      "svg-viewer"
    ).style.transform = `translate(${pointX}${type} , ${pointY}${type}) scale(${scale})`;
  }
  const callGetEquipmentMeta = async (getAllequipment) => {
    return await Promise.all(
      getAllequipment?.map(async (item) => {
        const URL = EQUIPMENT_GET + "/" + item;
        const getResponse = await getRequestcall(URL, access_token);
        if (getResponse?.status === 200) {
          let getSelected = {};
          return (getSelected = {
            ...getResponse?.data?.data,
            equipment_meta: JSON.parse(getResponse?.data?.data?.equipment_meta),
          });
        }
      })
    );
  };
  const callGetMediaDetails = async (getAllmedia) => {
    return await Promise.all(
      getAllmedia?.map(async (item) => {
        const URL = FILES_GET + "/" + item;
        const getResponse = await getRequestcall(URL, access_token);
        if (getResponse?.status === 200) {
          return getResponse?.data?.data;
        }
      })
    );
  };

  // After click on point get the equipment and media detail
  const CallSelectPointer = async (getItem) => {
    setHover(getItem?.id);
    const element = document.getElementById(getItem?.id);
    element?.classList.add("onPathClick")
    // // Create a new click event
    // const clickEvent = new MouseEvent('click', {
    //   bubbles: true, // Allow the event to bubble up the DOM
    //   cancelable: true, // Allow the event to be canceled
    //   view: window, // The view associated with the event
    // });

    // // Dispatch the click event to the path element
    // element.dispatchEvent(clickEvent);
    let getEquipmentdetail = []; // get equipment detail
    let getMediadetail = []; // get media detail
    if (getItem?.selected_equipment?.length > 0) {
      const getAllequipment = getItem?.selected_equipment;
      getEquipmentdetail = await callGetEquipmentMeta(getAllequipment);
    }
    if (typeof getItem?.selected_equipment == "string") {
      const getAllequipment = getItem?.selected_equipment.split(",");
      getEquipmentdetail = await callGetEquipmentMeta(getAllequipment);
    }

    if (getEquipmentdetail.length > 0) {
      setEquipmentmeta(getEquipmentdetail[0]?.equipment_meta);
      setTabState(getEquipmentdetail[0]?.id);
    }
    if (getItem?.selected_media?.length > 0) {
      // const getAllmedia = getItem?.selected_media?.split(",");
      const getAllmedia = getItem?.selected_media;
      getMediadetail = await callGetMediaDetails(getAllmedia);
    }
    if (typeof getItem?.selected_media == "string") {
      const getAllmedia = getItem?.selected_media.split(",");
      getMediadetail = await callGetMediaDetails(getAllmedia);
    }

    dispatch(
      setStoreSelectPointer(getItem, getEquipmentdetail, getMediadetail)
    );
    $("#equ-show").removeClass("active");
    setDisplayequipment(true);
    setShowEquipment(true);
    showResultbar();
  };

  const callShowPreview = async (item) => {
    const response = await fetch(
      process.env.REACT_APP_BASE_URL + "/media/" + item?.file_path
    );
    if (response?.status === 200) {
      const result = await response.blob();
      const payload = {
        ...item,
        show: true,
        isFile: true,
        file_path: item?.file_path,
        file_name: item?.file_name,
        file_url: URL.createObjectURL(result),
      };
      dispatch(setFilePreview(payload));
    } else {
      dispatch(
        ShowToast({
          description: "Anable to open file",
          show: true,
          type: "error",
        })
      );
    }
  };

  const showResultbar = () => {
    document.getElementById("design-viewer").style.width = "calc(100% - 337px)";
    document.getElementById("design-viewer").style.transition =
      "width .1s ease-in-out";
    document.getElementById("leftarrow").style.display = "none";
  };
 // // svg path highlight code
 const paths = document.querySelectorAll("path, ellipse, rect");
 let clickedPath = null;

 const handlePathHover = (event) => {
   // Reset all paths to remove the 'onPathHover' class.
   paths.forEach((path) => path.classList.remove("onPathHover"));

   // Set the border style on hover.
   event.target.style.strokeWidth = "10px";
   event.target.style.stroke = "#000";
 };

 const handlePathLeave = (event) => {
   // Reset the border style when the mouse leaves the path.
   event.target.style.strokeWidth = "1px";
   event.target.style.stroke = "#000";
 };

 const handlePathClick = (event) => {
   if (clickedPath !== null) {
     clickedPath.classList.remove("onPathClick");
   }
   // Add the 'onPathClick' class to the clicked path.
   event.target.classList.add("onPathClick");
   clickedPath = event.target;
 };

 const handleDocumentClick = (event) => {
   // Remove the 'onPathClick' class when the user clicks outside of the path.
   if (!event.target.classList.contains("onPathClick")) {
     paths.forEach((path) => path.classList.remove("onPathClick"));
     clickedPath = null;
   }
 };

 // Add event listeners to each path to handle the hover and click events.
 paths.forEach((path) => {
   path.addEventListener("mouseenter", handlePathHover);
   path.addEventListener("mouseleave", handlePathLeave);
   path.addEventListener("click", handlePathClick);
 });

 // Attach a global click event listener to handle clicks outside the path.
 document.addEventListener("click", handleDocumentClick);

  return (
    <aside className="single-page">
      <div id="info-box" />
      <aside className="body-content">
        <div className="floorplan" id="design-viewer">
          <div className="floorplan-control">
            <div className="floorplan-controls__left">
              <button
                onClick={(e) => {
                  if (scale <= 5.3) {
                    scale += 0.1;
                    setTransform(pointX, pointY, "px");
                  }
                }}
                className="btn floor-control btn-sm border-0"
              >
                <li>
                  <Icon className="bi bi-zoom-in" title="Zoom In" />
                </li>
              </button>
              <button
                onClick={(e) => {
                  if (scale >= 0.3) {
                    scale -= 0.1;
                    setTransform(pointX, pointY, "px");
                  }
                }}
                className="btn floor-control btn-sm border-0"
              >
                <li>
                  <Icon className="bi bi-zoom-out" title="Zoom Out" />
                </li>
              </button>
              <button
                onClick={(e) => {
                  scale = 1;
                  pointX = 0;
                  pointY = 0;
                  setTransform(pointX, pointY, "px");
                }}
                className="btn floor-control btn-sm border-0"
              >
                <li>
                  <i className="bi bi-arrows-fullscreen" title="Resize"></i>
                </li>
              </button>
            </div>
            <i
              className="bi bi-info-square-fill left-arrow"
              id="leftarrow"
              onClick={() => showResultbar()}
            ></i>
            {/* <img src={leftArrow} style={{height:"34px",cursor:"pointer"}} alt="" id="leftarrow" className="left-arrow" onClick={()=>showResultbar()} /> */}
          </div>
          <div className="floorplan-canvas-div">
            <div
              className="svg-viewer sortable-handler"
              id={"svg-viewer"}
              onMouseDown={function (e) {
                if (e.cancelable) e.preventDefault();
                start = { x: e.clientX - pointX, y: e.clientY - pointY };
                panning = true;
              }}
              onWheel={function (e) {
                if (e.cancelable) e.preventDefault();
                let xs = (e.clientX - pointX) / scale;
                let ys = (e.clientY - pointY) / scale;
                let delta = e.wheelDelta ? e.wheelDelta : -e.deltaY;
                delta > 0 ? (scale *= 1.1) : (scale /= 1.1);
                pointX = e.clientX - xs * scale;
                pointY = e.clientY - ys * scale;
                setTransform(pointX, pointY, "px");
              }}
              onMouseUp={function (e) {
                if (e.cancelable) e.preventDefault();
                panning = false;
              }}
              onMouseMove={function (e) {
                if (e.cancelable) e.preventDefault();
                if (!panning) {
                  return;
                }
                pointX = e.clientX - start.x;
                pointY = e.clientY - start.y;
                setTransform(pointX, pointY, "px");
              }}
              onClick={onClickPointer}
            >
              {selectDesignlist.length !== 0 ? (
                <div className="position-relative" id="add-image">
                  <div
                    dangerouslySetInnerHTML={{ __html: selectDesign?.svg_file }}
                    id="svg_polygon"
                    style={{ width: "100%", height: "auto" }}
                    ref={infoReference}
                  />

                  {selectDesign?.design_description?.allPoints?.map(
                    (item, index) =>
                      item?.sr_no !== "" && (
                        <span
                          key={index}
                          onClick={(e) => {
                            CallSelectPointer(item);
                          }}
                          style={{
                            left: item?.left,
                            top: item?.top,
                            visibility: "visible",
                          }}
                          // id={"pointer"}
                          // className={
                          //   hover === item?.id
                          //     ? "active pulse"
                          //     : ""
                          // }
                        >
                          {item?.sr_no}
                        </span>
                      )
                  )}
                </div>
              ) : (
                <>Data not found</>
              )}
            </div>
          </div>
        </div>
        {selectDesignlist.length !== 0 && (
          <ViewerSideBar
            CallSelectPointer={CallSelectPointer}
            ShowEquipment={ShowEquipment}
            setShowEquipment={setShowEquipment}
            setHover={setHover}
            callShowPreview={callShowPreview}
            hover={hover}
            tabState={tabState}
            setTabState={setTabState}
            setEquipmentmeta={setEquipmentmeta}
            equipmentMeta={equipmentMeta}
            setDisplayequipment={setDisplayequipment}
            displayEquipment={displayEquipment}
          />
        )}
      </aside>
      <FilePreview />
    </aside>
  );
}
